export type PlantMapping = {
  MappingID: number;
  TR2000PlantID: number;
  ShortDescription: string;
  RemoteSystemName: string;
  RemoteSystemPlantID: string;
};

export type PlantMappingUpdate = {
  MappingID: number;
  RemoteSystemPlantID: string;
};

export type PlantMappingCreate = {
  RemoteSystemName: string;
};

export const plantMappingConfig = {
  title: "Plant Mappings",
  singularTitle: "Plant Mapping",
  frontendUrl: "/admin/plant-mapping",
  queryKey: ["codelists", "plant-mapping"],
  submitProp: "plantmappings",
  nameProperty: [
    "MappingID",
    "TR2000PlantID",
    "ShortDescription",
    "RemoteSystemPlantID",
  ],
};
