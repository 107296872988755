export const issueStatusConfig = {
  O: "Official",
  W: "Working",
  R: "Replaced",
  S: "Sub-revision",
  M: "Master",
};

export type IssueStatus = keyof typeof issueStatusConfig;
export type MaybeIssueStatus = IssueStatus | undefined;
