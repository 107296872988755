import { Button } from "@equinor/eds-core-react";
import { PageContainer } from "components/Components";
import { ModalSideMargin, ModalWindow } from "components/ModalWindow";
import { useState } from "react";

export function ModalExample() {
  const [showModal, setShowModal] = useState(false);
  return (
    <PageContainer>
      <Button onClick={() => setShowModal(true)}>Show modal</Button>
      <ModalWindow
        closeModal={() => setShowModal(false)}
        isOpen={showModal}
        title={"Modal Example"}
      >
        <ModalSideMargin>I'm here</ModalSideMargin>
      </ModalWindow>
    </PageContainer>
  );
}
