import { SmallTableContainer } from "components/table/Table";
import { TableExample } from "./TableExample";

export function SmallTableExample() {
  return (
    <div style={{ padding: 20 }}>
      <SmallTableContainer>
        <TableExample />
      </SmallTableContainer>
    </div>
  );
}
