import { ReportsConfig } from "../Reports";

const wallThicknessColumns = [
  { key: "Temperature", title: "Temperature" },
  { key: "Pressure", title: "Pressure" },
  { key: "AllowableStress", title: "Allowable Stress" },
  { key: "AllowableStressRatio", title: "Allowable Stress Ratio" },
  { key: "PressureRatio", title: "Pressure Ratio" },
  { key: "PressureAllowableStress", title: "Pressure Allowable Stress" },
  { key: "Selected", title: "Selected" },
];

export type WallThicknessCalculationRatiosData = {
  Temperature: string;
  Pressure: string;
  AllowableStress: string;
  AllowableStressRatio: string;
  PressureRatio: string;
  PressureAllowableStress: string;
  Selected: "Y" | "N";
}[];

export const wallThicknessCalculationRatiosConfig: ReportsConfig = {
  title: "Wall Thickness Calculation Ratios",
  menuTitle: "Wall Thickness Calculation Ratios",
  listProperty: "getWallThicknessCalcFigures",
  exportFunction: (data) => ({
    columns: wallThicknessColumns,
    items: data.getWallThicknessCalcFigures,
  }),
  disableMenu: true,
};
