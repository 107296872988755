import { useCallback, useState } from "react";

export const useClientDimensions = <T extends HTMLElement>() => {
  const [clientWidth, setClientWidth] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const refCallback = useCallback((node: T) => {
    if (node !== null) {
      setClientWidth(node.clientWidth);
      setClientHeight(node.clientHeight);
    }
  }, []);

  return { clientWidth, clientHeight, refCallback };
};
