import { Typography } from "@equinor/eds-core-react";
import { useAppSelector } from "app/hooks";
import { HeaderContainer } from "../../components/Components";
import { selectMainPlant } from "./plantSlice";

function PlantPage() {
  const plant = useAppSelector(selectMainPlant);
  return (
    <HeaderContainer>
      <Typography variant="h2">
        {plant.LongDescription} ({plant.ShortDescription})
      </Typography>
      <Typography variant="h4">{plant.OperatorName}</Typography>
      <p>Select a sheet type.</p>
    </HeaderContainer>
  );
}

export default PlantPage;
