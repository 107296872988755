import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import plantReducer from "../features/plant/plantSlice";
import sheetReducer from "../features/sheets/sheetSlice";
import uiReducer from "../uiSlice";

export const store = configureStore({
  reducer: {
    plant: plantReducer,
    sheet: sheetReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
