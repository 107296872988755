import { ReportsConfig } from "../Reports";
import { mdsVDSSearchColumns } from "./MDSVDSSearchReport";

export type MDSVDSSearchData = {
  MDS: string;
  VDS: string;
  Revision: string;
  Status: string;
  RevDate: string;
  LastUpdate: string;
}[];

export const mdsVDSSearchConfig: ReportsConfig = {
  title: "MDS usage in VDS",
  menuTitle: "MDS usage in VDS",
  listProperty: "getMDSSearchVDS",
  exportFunction: (data) => ({ columns: mdsVDSSearchColumns, items: data }),
};
