import { Route } from "react-router-dom";
import SheetsTestpageList from "./SheetsTestpageList";
import SheetsTestpage from "./SheetsTestpage";
import PipeElementsTestpage from "./PipeElementsTestpage";
import { sheetTypes } from "../sheets/config/sheetConfig";
import TableTestpage from "./TableTestpage";
import TableGroupTestpage from "./TableGroupTestpage";
import ModalWindowTestpage from "./ModalWindowTestpage";

export default function Testpages() {
  return (
    <>
      <Route path={`/testpage/sheets/:sheetType(${sheetTypes.join("|")})`}>
        <SheetsTestpage />
      </Route>
      <Route path={`/testpage/pipe-elements/`}>
        <PipeElementsTestpage />
      </Route>
      <Route path={`/testpage/sheets/`}>
        <SheetsTestpageList />
      </Route>
      <Route path={`/testpage/table/`}>
        <TableTestpage />
      </Route>
      <Route path={`/testpage/tablegroup/`}>
        <TableGroupTestpage />
      </Route>
      <Route path={`/testpage/modalwindow/`}>
        <ModalWindowTestpage />
      </Route>
    </>
  );
}
