import { ListAdminCommonProps, ListAdminConfig } from "../listAdminConfig";

export type PFL2Mapping = {
  MappingID: number;
  ElementGroupID: number;
  ElementGroup: string;
  ElementGroupSortSequence: number;
  DimensionStandard: string;
  ProductForm: string;
  MaterialStandard: string;
  MaterialGrade: string;
  ProductFormLevel2ID: number;
  ProductFormLevel2: string;
} & ListAdminCommonProps;

export const pfl2MappingConfig: ListAdminConfig<"product-form-level2-mapping"> =
  {
    title: "Product Form Level 2 Mappings",
    singularTitle: "Product Form Level 2 Mapping",
    url: "codelists/product-form-level2-mapping",
    confirmPFL2: true,
    itemIdProp: "MappingID",
    itemNameProp: "MappingID",
    apiListProp: "getPFL2Mapping",
    submitProp: "pfl2mappings",
    groupBy: "ElementGroupID",
    groupTitle: "ElementGroup",
    groupIdProp: "ElementGroupID",
    nameProperty: [
      "ElementGroup",
      "MappingID",
      "DimensionStandard",
      "ProductForm",
      "MaterialStandard",
      "MaterialGrade",
      "ProductFormLevel2",
    ],
    columns: [
      {
        key: "ElementGroupID",
        title: "Element Group",
        codelist: "pipe-element-groups",
      },
      {
        key: "MappingID",
        title: "ID",
      },
      {
        key: "DimensionStandard",
        title: "Dim. Standard",
        longTitle: "Dimension Standard",
      },
      {
        key: "ProductForm",
        title: "Product Form",
        width: 180,
      },
      {
        key: "MaterialStandard",
        title: "Material Standard",
        width: 180,
      },
      {
        key: "MaterialGrade",
        title: "Material Grade",
      },
      {
        key: "ProductFormLevel2ID",
        title: "Product Form Level 2",
        codelist: "product-form-level2",
        width: 240,
      },
    ],
    codelistsToQuery: ["pipe-element-groups", "product-form-level2"],
  };

export function preparePFL2MappingData(
  submitData: PFL2Mapping[],
  confirmElementGroup?: number
) {
  const { submitProp } = pfl2MappingConfig;
  let prevGroup = 0;
  let index = 0;
  return {
    [submitProp]: submitData
      .map((e: Partial<PFL2Mapping>, i: number) => {
        if (e.ElementGroupID && prevGroup !== Number(e.ElementGroupID)) {
          index = 1;
          prevGroup = Number(e.ElementGroupID);
        } else {
          index++;
        }
        return { ...e, ElementGroupSortSequence: index };
      })
      .filter(
        (e) => !confirmElementGroup || e.ElementGroupID === confirmElementGroup
      ) as Partial<PFL2Mapping>[],
    ...(confirmElementGroup ? { ElementGroupID: confirmElementGroup } : {}),
  };
}
