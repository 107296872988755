import React, { useState, useEffect } from "react";
import { Button } from "@equinor/eds-core-react";
import styled from "styled-components";

const ToggleButtonsContainer = styled.div`
  margin-bottom: 0.75em;
  button {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      border-left-width: 0;
    }
  }
`;

export function useToggleButtons({
  buttons,
  id,
}: {
  buttons: { key: string; title: string }[];
  id: string;
}): { toggleButtonsContent: React.ReactFragment; activeButton: string } {
  const activeButtonSession = window.sessionStorage.getItem(id);
  const [activeButton, setActiveButton] = useState(
    activeButtonSession ? activeButtonSession : buttons[0].key
  );
  useEffect(() => {
    window.sessionStorage.setItem(id, String(activeButton));
  }, [activeButton, id]);

  return {
    activeButton,
    toggleButtonsContent: (
      <ToggleButtonsContainer>
        {buttons.map((button, index) => (
          <Button
            key={button.key}
            onClick={() => setActiveButton(button.key)}
            variant={button.key === activeButton ? "contained" : "outlined"}
          >
            {button.title}
          </Button>
        ))}
      </ToggleButtonsContainer>
    ),
  };
}
