import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const FillContainer = styled(Container)`
  height: 100%;
`;

const FillElement = styled.div`
  display: flex;
  flex: 1 1 100%;
  overflow: visible;
  flex-direction: column;
`;

const NormalElement = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const FillContent = styled.div`
  height: 100%;
  overflow: auto;
`;

const NormalContent = styled.div``;

const CenterContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Area = {
  FillContainer,
  Container,
  FillElement,
  NormalElement,
  FillContent,
  NormalContent,
  CenterContent,
};

export default Area;
