import { ReportsConfig } from "../Reports";
import { vdsPCSSearchColumns } from "./VDSPCSSearchReport";

export type VDSPCSSearchData = {
  VDS: string;
  Plant: string;
  PCS: string;
  Revision: string;
  Status: string;
  RevDate: string;
  LastUpdate: string;
}[];

export const vdsPCSSearchConfig: ReportsConfig = {
  title: "VDS usage in PCS",
  menuTitle: "VDS usage in PCS",
  listProperty: "getVDSSearchPCS",
  exportFunction: (data) => ({ columns: vdsPCSSearchColumns, items: data }),
};
