import { ReportsConfig } from "../Reports";
import { edsPCSSearchColumns } from "./EDSPCSSearchReport";

export type EDSPCSSearchData = {
  SST: string;
  Plant: string;
  PCS: string;
  Revision: string;
  Status: string;
  RevDate: string;
  LastUpdate: string;
}[];

export const edsPCSSearchConfig: ReportsConfig = {
  title: "EDS usage in PCS",
  menuTitle: "EDS usage in PCS",
  listProperty: "getSSTSearchPCS",
  exportFunction: (data) => ({ columns: edsPCSSearchColumns, items: data }),
};
