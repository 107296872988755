import { pcsSPEIssueMDSValidationReportColumns } from "./SPEIssueMDSValidationReport";
import { ReportsConfig } from "../Reports";

export type PCSSPEIssueMDSValidationData = {
  Plant: string;
  PCS: string;
  Revision: string;
  Status: string;
  Item: string;
  ElementGroup: string;
  DimensionStandard: string;
  ProductForm: string;
  Material: string;
  ElementID: string;
  ElementRevision: string;
  PCSMDS: string;
  SPEMDS: string;
  SPEMDSRevision: string;
  SPEMDSArea: string;
  IssueMDS: string;
  IssueMDSRevision: string;
  IssueMDSArea: string;
}[];

export const pcsSPEIssueMDSValidationConfig: ReportsConfig = {
  title: "Validate PCS/SPE and MDS in Issue",
  menuTitle: "Validate PCS/SPE and MDS in Issue",
  listProperty: "getPCSSPE_IssueMDS",
  exportFunction: (data) => ({
    columns: pcsSPEIssueMDSValidationReportColumns,
    items: data,
  }),
};
