import { ColumnsProps } from "../components/table/Table";
import xlsx from "json-as-xlsx";

// The excel parameter is a workaround for better Excel formatting,
// but as it makes the CSV non-standard, it has been decided to disable it.
// For Excel, we have the xlsx download instead.
// https://stackoverflow.com/a/165052/1535873

function escapeForCSV(data: string, excel = false) {
  const escaped = data.replace(/"/g, '""');
  return excel ? `"=""${escaped}"""` : `"${escaped}"`;
}

function produceCSVArray(columns: ColumnsProps[], items: object[]) {
  const arr = [
    columns
      .map((c) =>
        typeof c.title === "string"
          ? escapeForCSV(c.title)
          : escapeForCSV(c.key)
      )
      .join(),
    ...items.map((row) =>
      columns
        .map((rc) => {
          const value = rc.key in row ? row[rc.key as keyof typeof row] : "";
          return value !== null && value !== undefined
            ? escapeForCSV(String(value))
            : "";
        })
        .join()
    ),
  ];
  return arr.join("\n");
}

export function downloadAsCSV({
  columns,
  items,
  filename,
}: {
  columns: ColumnsProps[];
  items: any[];
  filename: string;
}) {
  const data = produceCSVArray(columns, items);
  const blob = new Blob([data], { type: "text/csv" });

  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = filename.replaceAll(" ", "_");

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export const downloadAsXLSX = ({
  columns,
  items,
  filename,
}: {
  columns: ColumnsProps[];
  items: any[];
  filename: string;
}) => {
  let data = [
    {
      sheet: "Report",
      columns: columns.map((c) => ({
        label: typeof c.title === "string" ? c.title : c.key,
        value: c.key,
      })),
      content: items,
    },
  ];
  let settings = {
    fileName: filename.replaceAll(" ", "_"),
  };
  xlsx(data, settings);
};
