import { NativeSelect } from "@equinor/eds-core-react";
import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer, FormRow } from "components/form/Form";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { QueryStatus } from "react-query";
import { ListAdminProps } from "../listAdminConfig";

export function PFL2MappingEdit({
  item,
  data,
  setEditLoading,
  status,
  position,
  setPosition,
}: {
  item?: ListAdminProps<"product-form-level2-mapping">;
  data?: ListAdminProps<"product-form-level2-mapping">[];
  setEditLoading: React.Dispatch<boolean>;
  status: QueryStatus;
  position: number;
  setPosition: React.Dispatch<number>;
}) {
  const { data: pipeElementGroups, status: pipeElementGroupsStatus } =
    useCodelist({
      codelist: "pipe-element-groups",
    });

  const pipeElementGroupsOptions = useMemo(
    () =>
      pipeElementGroups
        ? pipeElementGroups.map((e) => ({
            id: String(e.ElementGroupID),
            option: e.ElementGroup,
          }))
        : [],
    [pipeElementGroups]
  );

  const { data: pfl2, status: pfl2Status } = useCodelist({
    codelist: "product-form-level2",
  });

  const pfl2Options = useMemo(
    () =>
      pfl2
        ? pfl2.map((e) => ({
            id: String(e.ProductFormLevel2ID),
            option: e.Description,
          }))
        : [],
    [pfl2]
  );

  const combinedQueryStatuses = useMemo(
    () => combineQueryStatuses(pipeElementGroupsStatus, pfl2Status, status),
    [pipeElementGroupsStatus, pfl2Status, status]
  );

  useEffect(() => {
    setEditLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    combinedQueryStatuses === "success"
      ? setEditLoading(false)
      : setEditLoading(true);
  }, [combinedQueryStatuses, setEditLoading]);

  const { watch, register, setValue } = useFormContext();
  const elementGroupId = watch("ElementGroupID");
  const pfl2Id = watch("ProductFormLevel2ID");

  const insertPositions = useMemo(() => {
    const belowPositions =
      data && elementGroupId
        ? data
            .filter((e) => !item || e.MappingID !== item.MappingID)
            .map((e, i) => ({
              id: i + 1,
              option: `Below ${e.MappingID}`,
              elementGroupID: e.ElementGroupID,
            }))
            .filter((e) => e.elementGroupID === elementGroupId)
        : [];
    return data &&
      pipeElementGroups &&
      data.filter((e) => e.ElementGroupID === elementGroupId).length === 0
      ? [{ id: data.length + 1, option: "New Element Group" }]
      : belowPositions.length > 0
      ? [
          { id: belowPositions[0].id - 1, option: "First position" },
          ...belowPositions,
        ]
      : [];
  }, [data, elementGroupId, pipeElementGroups, item]);

  useEffect(() => {
    setValue(
      "ElementGroup",
      pipeElementGroups?.find((e) => e.ElementGroupID === elementGroupId)
        ?.ElementGroup
    );
  }, [elementGroupId, pipeElementGroups, setValue]);

  useEffect(() => {
    setValue(
      "ProductFormLevel2",
      pfl2?.find((e) => e.ProductFormLevel2ID === pfl2Id)?.Description
    );
  }, [pfl2, pfl2Id, setValue]);

  useEffect(() => {
    !item && insertPositions.length > 0 && setPosition(insertPositions[0].id);
  }, [item, insertPositions, setPosition]);

  return (
    <>
      <FieldsetContainer slim>
        <Fieldset>
          <Field
            area="product-form-level2-mapping"
            prop="ElementGroupID"
            value={item ? item.ElementGroupID : ""}
            title="Element Group"
            type="option"
            optionsWithIds={pipeElementGroupsOptions}
            disableNotSetOption={true}
            status={pipeElementGroupsStatus}
            disabled={!!item}
            valueAsNumber={true}
            required={true}
          />
          <input
            type="hidden"
            defaultValue={
              pipeElementGroups?.find(
                (e) => e.ElementGroupID === elementGroupId
              )?.ElementGroup
            }
            {...register("ElementGroup")}
          />
          <FormRow>
            <NativeSelect
              label="Position"
              value={position}
              id="position"
              onChange={(e) => setPosition(Number(e.target.value))}
            >
              {insertPositions.map((e) => (
                <option value={e.id} key={e.id + e.option}>
                  {e.option}
                </option>
              ))}
            </NativeSelect>
          </FormRow>
        </Fieldset>
        <Fieldset>
          <Field
            area="product-form-level2-mapping"
            prop="DimensionStandard"
            value={item ? item.DimensionStandard : "*"}
            title="Dimension Standard"
            type="text"
            required={true}
          />
          <Field
            area="product-form-level2-mapping"
            prop="ProductForm"
            value={item ? item.ProductForm : "*"}
            title="Product Form"
            type="text"
          />
          <Field
            area="product-form-level2-mapping"
            prop="MaterialStandard"
            value={item ? item.MaterialStandard : "*"}
            title="Material Standard"
            type="text"
          />
          <Field
            area="product-form-level2-mapping"
            prop="MaterialGrade"
            value={item ? item.MaterialGrade : "*"}
            title="Material Grade"
            type="text"
          />
          <Field
            area="product-form-level2-mapping"
            prop="ProductFormLevel2ID"
            value={item ? item.ProductFormLevel2ID : ""} // @todo
            optionsWithIds={pfl2Options}
            title="Product Form Level 2"
            status={pfl2Status}
            type="option"
            valueAsNumber={true}
            required={true}
          />
          <input type="hidden" {...register("ProductFormLevel2")} />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
