import { ReportsConfig } from "../Reports";

export const ratingGroupsConfig: ReportsConfig = {
  title: "Rating Groups",
  menuTitle: "Rating Groups",
  listProperty: "getRatingGroup",
  exportFunction: (data) => ({
    columns: [
      { key: "GroupID", title: "Group ID" },
      { key: "RatingClass", title: "Rating Class" },
      { key: "Temperature", title: "Temperature" },
      { key: "Pressure", title: "Pressure" },
    ],
    items: data,
  }),
};

export type RatingGroupsData = {
  GroupID: string;
  RatingClass: string;
  Temperature: number;
  Pressure: number;
}[];
