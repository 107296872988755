import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { EditComponentProps } from "../ItemEditModal";

export function VDSCharacteristicsEdit({
  item,
}: EditComponentProps<"vds-characteristics">) {
  return (
    <>
      <FieldsetContainer>
        <Fieldset>
          <Field
            area="vds-characteristics"
            prop="Description"
            value={item ? item.Description : ""}
            title="Description"
            type="text"
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
