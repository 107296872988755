import { Checkbox } from "@equinor/eds-core-react";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  AbsoluteFlexContainer,
  FlexContentElement,
} from "components/Components";
import {
  Fieldset,
  FieldsetContainer,
  FormOptionsContainer,
} from "components/form/Form";
import { Field } from "../../../components/form/Field";
import { SheetTypes } from "../config/sheetConfig";

export function prepareData(data: {
  Notepad: string;
  ArticleContent: "Y" | false;
}): { Notepad: string; ArticleContent: "Y" | "N" } {
  return { ...data, ArticleContent: data.ArticleContent ? "Y" : "N" };
}

export function Properties({
  item,
  setIsLoaded,
  sheetType,
}: {
  item: any;
  setIsLoaded: React.Dispatch<boolean>;
  sheetType: SheetTypes;
}) {
  const { register } = useFormContext();

  useEffect(
    () => {
      setIsLoaded(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <AbsoluteFlexContainer>
      <FlexContentElement>
        <FieldsetContainer>
          <Fieldset>
            <Field
              prop="Notepad"
              title="Note Information"
              type="text"
              rows={3}
              rowsMax={12}
              maxLength={100}
              value={item.Notepad}
            />
            {sheetType === "eds" && (
              <FormOptionsContainer alignLeft>
                <Checkbox
                  label="EDS content should be synchronised with Article DB content"
                  defaultChecked={item.ArticleContent === "Y"}
                  value="Y"
                  {...register("ArticleContent")}
                />
              </FormOptionsContainer>
            )}
          </Fieldset>
        </FieldsetContainer>
      </FlexContentElement>
    </AbsoluteFlexContainer>
  );
}
