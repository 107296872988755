import { ElementProperties } from "../hooks/useElementTable";

export const elementProperties: { [key: string]: ElementProperties } = {
  "pipe-elements": {
    getProp: "getPipeElement",
    submitProp: "pipeelements",
    groupName: "pipe-element-groups",
    elementGroupIdProp: "ElementGroupNo",
    groupIdProp: "ElementGroupID",
    groupNameProp: "ElementGroup",
  },
  "valve-elements": {
    getProp: "getValveElement",
    submitProp: "valveelements",
    groupName: "valve-element-groups",
    elementGroupIdProp: "ValveGroupNo",
    groupIdProp: "ValveGroupID",
    groupNameProp: "ValveGroup",
  },
};
