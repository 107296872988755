import { check } from "@equinor/eds-icons";
import { IconColumn } from "components/table/Columns";
import { ListAdminCommonProps, ListAdminConfig } from "../listAdminConfig";

export type MaterialGroups = {
  MaterialGroupID: number;
  MaterialGroup: string;
  Description: string;
  Density: number;
  VDSCode1: string;
  VDSCode2: string | null;
  Sequence: number;
  MaterialType: string;
  PEBEWthMM: number;
  MinDesignTemperature: number;
  AllowableStress: number;
  InUse: "Y" | "N";
} & ListAdminCommonProps;

export const materialGroupsConfig: ListAdminConfig<"material-groups"> = {
  title: "Material Groups",
  singularTitle: "Material Group",
  url: "codelists/material-groups",
  itemIdProp: "MaterialGroupID",
  itemNameProp: "MaterialGroup",
  apiListProp: "getMaterialGroup",
  submitProp: "materialgroups",
  nameProperty: [
    "MaterialGroupID",
    "MaterialGroup",
    "Description",
    "Density",
    "MaterialType",
    "VDSCode1",
    "VDSCode2",
  ],
  columns: [
    {
      key: "MaterialGroupID",
      title: "ID",
    },
    {
      key: "MaterialGroup",
      title: "Material Group",
    },
    {
      key: "Description",
      title: "Description",
      width: 200,
    },
    {
      key: "Density",
      title: "Density (kg/m³)",
    },
    { key: "VDSCode1", title: "MDS/VDS #1" },
    { key: "VDSCode2", title: "MDS/VDS #2" },
    { key: "MaterialType", title: "Material Type", width: 200 },
    { key: "PEBEWthMM", title: "PE/BE (mm)" },
    {
      key: "MinDesignTemperature",
      title: "Min. Des. Temp.",
      longTitle: "Minimum Design Temperature",
    },
    {
      key: "AllowableStress",
      title: "Allow. Stress (Olet)",
      longTitle: "Allowable Stress (Olet)",
    },
    {
      key: "InUse",
      title: "In Use",
      Component: IconColumn,
      type: "with-context",
      componentProps: {
        trueValue: "Y",
        falseValue: "N",
        prop: "InUse",
        title: "In Use",
        icon: check,
      },
    },
  ],
  disableDelete: (item: MaterialGroups) => item.InUse === "Y",
  readonly: (caps) => !caps.includes("material_group_write"),
};

export function prepareMaterialGroupsData(submitData: MaterialGroups[]) {
  const { submitProp } = materialGroupsConfig;
  return {
    [submitProp]: submitData.map((e: Partial<MaterialGroups>, i: number) => {
      delete e.InUse;
      return { ...e, Sequence: i + 1 };
    }),
  };
}
