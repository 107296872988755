import React from "react";
import { Issue } from "queries/useIssues";
import { ItemsClassNames } from "../../components/table/Table";
import { EditSheet } from "./EditIssue";

interface EditIssueContextProps {
  revision: string;
  issue?: Issue;
  editSheet: EditSheet;
  setEditSheet: React.Dispatch<EditSheet>;
  editSheetOpen: boolean;
  setEditSheetOpen: React.Dispatch<boolean>;
  itemsClassNames: ItemsClassNames[];
  setItemsClassNames: React.Dispatch<ItemsClassNames[]>;
  isEditable?: boolean;
  showValidation: boolean;
  setShowValidation: React.Dispatch<boolean>;
  wantToSetStatus: string;
  setWantToSetStatus: React.Dispatch<string>;
}

export const EditIssueContext = React.createContext(
  {} as EditIssueContextProps
);

export const EditIssueContextProvider = (
  props: EditIssueContextProps & { children: React.ReactNode }
) => {
  return (
    <EditIssueContext.Provider value={props}>
      {props.children}
    </EditIssueContext.Provider>
  );
};
