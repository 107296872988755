import React from "react";
import { Issue } from "queries/useIssues";
import { IssuesCommandType } from "queries/useIssuesCommand";

interface IssuesContextProps {
  issuesCommand: IssuesCommandType["mutate"];
  issues?: Issue[];
  isEditable?: boolean;
}

export const IssuesContext = React.createContext({} as IssuesContextProps);

export const IssuesContextProvider = (
  props: IssuesContextProps & { children: React.ReactNode }
) => {
  return (
    <IssuesContext.Provider value={props}>
      {props.children}
    </IssuesContext.Provider>
  );
};
