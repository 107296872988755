import React, { useState } from "react";
import { Input, Label, Tooltip } from "@equinor/eds-core-react";
import { FlexContainer, FlexElement } from "../../../components/Components";
import { PCSReferenceSheetTypes } from "../config/sheetConfig";

export default function usePipeDimensions({
  formState,
  register,
  referenceSheetType,
  defaultFromSize,
  defaultToSize,
}: {
  formState: any;
  register: Function;
  referenceSheetType?: PCSReferenceSheetTypes;
  defaultFromSize?: string;
  defaultToSize?: string;
}): {
  fromSize: string;
  toSize: string;
  pipeDimensionsContent: React.ReactFragment;
} {
  const min = 0;
  const max = 1000;
  const [fromSize, setFromSize] = useState(
    defaultFromSize ? defaultFromSize : ""
  );
  const [toSize, setToSize] = useState(defaultToSize ? defaultToSize : "");

  // SC and VSM does not have dimensions
  if (referenceSheetType && referenceSheetType !== "vds") {
    return {
      fromSize: "",
      toSize: "",
      pipeDimensionsContent: <></>,
    };
  }

  return {
    fromSize,
    toSize,
    pipeDimensionsContent: (
      <>
        <FlexContainer style={{ justifyContent: "flex-start" }}>
          <FlexElement style={{ paddingRight: "1.5em" }}>
            <Label label="From Size" htmlFor="fromSize" />
            <Tooltip
              title={
                formState.errors && formState.errors.fromSize
                  ? formState.errors.fromSize.message
                    ? formState.errors.fromSize.message
                    : formState.errors.fromSize.type === "min" ||
                      formState.errors.fromSize.type === "max"
                    ? `Value must be between ${min} and ${max}`
                    : ""
                  : ""
              }
            >
              <Input
                id="fromSize"
                defaultValue={defaultFromSize}
                {...register("fromSize", {
                  min,
                  max,
                  validate: (value: string) =>
                    /^[0-9.]*$/g.test(value) ||
                    "Must be a number with optional . (dot) as decimal separator.",
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setFromSize(e.target.value),
                })}
                variant={
                  formState?.errors && formState.errors.fromSize
                    ? "error"
                    : "default"
                }
              />
            </Tooltip>
          </FlexElement>
          <FlexElement>
            <Label label="To Size" htmlFor="toSize" />
            <Tooltip
              title={
                formState.errors && formState.errors.toSize
                  ? formState.errors.toSize.message
                  : ""
              }
            >
              <Input
                id="toSize"
                defaultValue={defaultToSize}
                {...register("toSize", {
                  min,
                  max,
                  validate: (value: string) =>
                    /^[0-9.]*$/g.test(value) ||
                    "Must be a number with optional . (dot) as decimal separator.",
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setToSize(e.target.value),
                })}
                variant={
                  formState?.errors && formState.errors.toSize
                    ? "error"
                    : "default"
                }
              />
            </Tooltip>
          </FlexElement>
        </FlexContainer>
      </>
    ),
  };
}
