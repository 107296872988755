import { ReportsConfig } from "../Reports";
import { mdsPCSSearchColumns } from "./MDSPCSSearchReport";

export type MDSPCSSearchData = {
  MDS: string;
  Plant: string;
  PCS: string;
  Revision: string;
  Status: string;
  RevDate: string;
  LastUpdate: string;
}[];

export const mdsPCSSearchConfig: ReportsConfig = {
  title: "MDS usage in PCS",
  menuTitle: "MDS usage in PCS",
  listProperty: "getMDSSearchPCS",
  exportFunction: (data) => ({ columns: mdsPCSSearchColumns, items: data }),
};
