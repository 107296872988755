import { useMsal } from "@azure/msal-react";
import { Typography } from "@equinor/eds-core-react";
import { useAppSelector } from "app/hooks";
import { appProps } from "app/props";
import { AdminRoles, UserRoles, adminRoles, userRoles } from "app/userConfig";
import useUser from "queries/useUser";
import styled from "styled-components";
import { selectUIState } from "uiSlice";

const StatusBarContainer = styled.div`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 3px;
`;

const StatusBarContainerOut = styled.div`
  padding: 20px;
`;

const StatusBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const userRoleName = (role: UserRoles, adminRole: AdminRoles) =>
  `${
    role === "A" && ["X", "P", "V"].includes(adminRole)
      ? `${adminRoles[adminRole]} `
      : ""
  }${userRoles[role]}`;

export function StatusBar({ out }: { out?: boolean }) {
  const { accounts } = useMsal();
  const { data: userData } = useUser();
  const { showRoleTest, roleTest, adminRoleTest } =
    useAppSelector(selectUIState);

  const Container = out ? StatusBarContainerOut : StatusBarContainer;

  return (
    <Container>
      <Typography variant="overline" as="div">
        <StatusBarContent>
          <div>
            <span title={process.env.REACT_APP_TITLE}>Build:</span>{" "}
            {appProps.version}
          </div>
          {accounts && accounts[0] && (
            <div>
              Signed in as {accounts[0].name || accounts[0].username}
              {userData &&
                ` (${userRoleName(
                  userData.userInfo.Role,
                  userData.userInfo.AdminRole
                )})`}
              {showRoleTest && roleTest && adminRoleTest && (
                <span style={{ color: "red" }}>
                  {" "}
                  Testing: {userRoleName(roleTest, adminRoleTest)}
                </span>
              )}
            </div>
          )}
        </StatusBarContent>
      </Typography>
    </Container>
  );
}
