import { RevisionMark } from "components/Components";
import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type PlantCreate = {
  ShortDescription: string;
  Project: string;
  LongDescription: string;
  EnableEmbeddedNote: "N" | "Y";
  CategoryID: number;
  OperatorID: number;
  EnableCopyPCSFromPlant: "N" | "Y";
  DocumentSpaceLink: "N" | "Y";
  AreaID: number;
  OverLength: "N" | "Y";
  EDSMJ: "N" | "Y";
  InitialRevision: "1" | "0";
  PCSQA: "N" | "Y";
  CelsiusBar: "N" | "Y";
  WebInfoText: string;
  WindowsRemarkText: string;
  BoltTensionText: string;
  Visible: "N" | "Y";
};

export type PlantUpdate = PlantCreate & CommonUpdateProps;

export const plantsConfig: ItemAdminConfig<"plants"> = {
  title: "Plants",
  singularTitle: "Plant",
  url: "plants",
  itemIdProp: "PlantID",
  apiListProp: "getPlant",
  deleteConfirmation: "The plant and all data connected to it will be deleted",
  nameProperty: [
    "PlantID",
    "ShortDescription",
    "LongDescription",
    "OperatorName",
    "Project",
    "CommonLibPlantCode",
  ],
  columns: [
    {
      key: "PlantID",
      title: "Plant ID",
    },
    { key: "ShortDescription", title: "Short Description" },
    { key: "LongDescription", title: "Long Description", width: 200 },
    {
      key: "OperatorName",
      title: "Operator Name",
    },
    { key: "Project", title: "Project" },
    {
      key: "CommonLibPlantCode",
      title: "Common Lib Plant Code",
    },
    {
      key: "InitialRevision",
      title: "Init. Rev.",
      Component: RevisionMark,
    },
    { key: "Area", title: "Area", width: 160 },
  ],
  initialSortBy: { key: "ShortDescription", mode: "string", order: "asc" },
};
