import styled from "styled-components";
import * as R from "ramda";

export const merge = (...nodes) => {
  const rules = R.compose(
    R.flatten,
    R.pluck("rules"),
    R.pluck("componentStyle")
  )(nodes);
  return styled.div`
    ${rules}
  `;
};
