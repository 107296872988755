export const userRoles = {
  X: "System Administrator",
  A: "Administrator",
  S: "Super User",
  U: "User",
  N: "None",
} as const;

export type UserRoles = keyof typeof userRoles;

export const userPower = {
  N: 0,
  U: 1,
  S: 2,
  A: 3,
  X: 4,
} as const;

export const adminRoles = {
  X: "Pipe & Valve",
  P: "Pipe",
  V: "Valve",
  A: "-",
  "": "-",
};

export type AdminRoles = keyof typeof adminRoles;
