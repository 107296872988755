import { useState, useEffect } from "react";

export function useTabs({
  name,
  changeConfirm,
}: {
  name: string;
  changeConfirm?: () => Promise<boolean>;
}): {
  activeTab: number;
  handleChange: (index: number) => void;
} {
  const activeTabSession = Number(window.sessionStorage.getItem(name));
  const [activeTab, setActiveTab] = useState(
    activeTabSession ? activeTabSession : 0
  );
  const handleChange = async (index: number) => {
    changeConfirm
      ? (await changeConfirm()) && setActiveTab(index)
      : setActiveTab(index);
  };
  useEffect(() => {
    window.sessionStorage.setItem(name, String(activeTab));
  }, [activeTab, name]);

  return {
    activeTab,
    handleChange,
  };
}
