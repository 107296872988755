import { AccessReports } from "features/reports/AccessReports";
import { Route, Switch } from "react-router-dom";

export function AppOut() {
  return (
    <div
      style={{
        position: "relative",
        flex: 1,
      }}
    >
      <Switch>
        <Route path="/out/reports/:report+">
          <AccessReports />
        </Route>
        <Route>
          <>No such location.</>
        </Route>
      </Switch>
    </div>
  );
}
