import Table, { TableProps } from "components/table/Table";

export const exampleItems = new Array(100).fill("").map((_, i) => ({
  id: i,
  txt: i % 2 ? "World" : "Hello",
}));

export type ExampleItemType = typeof exampleItems[number];

const exampleColumns = [
  { key: "id", title: "ID" },
  {
    key: "txt",
    title: "Text",
  },
];

export function TableExample(props: Partial<TableProps>) {
  return (
    <Table
      columns={exampleColumns}
      items={exampleItems}
      itemIdProp="id"
      {...props}
    />
  );
}
