import Sheets from "../sheets/Sheets";
import { selectMainPlant } from "../plant/plantSlice";
import { useAppSelector } from "../../app/hooks";
import { useParams } from "react-router-dom";
import { SheetTypes } from "../sheets/config/sheetConfig";
import { Typography } from "@equinor/eds-core-react";

export default function SheetsTestpage() {
  const mainPlant = useAppSelector(selectMainPlant);
  const { sheetType } = useParams<{ sheetType: SheetTypes }>();
  return (
    <>
      <Typography variant="overline">Test: Sheets</Typography>
      <Sheets plant={mainPlant} sheetType={sheetType} displayType="main" />
    </>
  );
}
