import { NativeSelect } from "@equinor/eds-core-react";
import { Field } from "components/form/Field";
import { FieldsetContainer, Fieldset, FormRow } from "components/form/Form";
import { useMemo, useRef } from "react";
import { ListAdminProps } from "../listAdminConfig";

export function VDSSubsegmentGroupsEdit({
  item,
  data,
  position,
  setPosition,
}: {
  item?: ListAdminProps<"vds-subsegment-groups">;
  data?: ListAdminProps<"vds-subsegment-groups">[];
  position: number;
  setPosition: React.Dispatch<number>;
}) {
  const insertPositions = useMemo(
    () => [
      { id: 0, option: "First position" },
      {
        id: data ? data.length - (!!item ? 1 : 0) : 1,
        option: "Last position",
      },
      ...(data
        ? data
            .filter((e) => !item || item.GroupID !== e.GroupID)
            .map((e, i) => ({
              id: i + 1,
              option: `Below ${e.Description} [${e.GroupID}]`,
            }))
        : []),
    ],
    [data, item]
  );

  const { current: optionalOptions } = useRef(["Y", "N"]);

  return (
    <>
      <FieldsetContainer slim>
        <Fieldset>
          <FormRow>
            <NativeSelect
              label="Position"
              value={position}
              id="position"
              onChange={(e) => setPosition(Number(e.target.value))}
            >
              {insertPositions.map((e) => (
                <option value={e.id} key={e.id + e.option}>
                  {e.option}
                </option>
              ))}
            </NativeSelect>
          </FormRow>
        </Fieldset>
        <Fieldset>
          <Field
            area="vds-subsegment-groups"
            prop="Description"
            value={item ? item.Description : ""}
            title="Description"
            type="text"
          />
          <Field
            area="vds-subsegment-groups"
            prop="OptionalSubsegment"
            value={item ? item.OptionalSubsegment : "N"}
            title="Optional"
            type="option"
            options={optionalOptions}
            maxWidth={50}
            disableNotSetOption={true}
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
