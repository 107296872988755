import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../app/authConfig";
import { Button } from "@equinor/eds-core-react";

function handleLogin(instance: any) {
  instance.loginRedirect(loginRequest).catch((e: Error) => {
    console.error(e);
  });
}

export const signInStateMessages = {
  acquireToken: "Acquiring token...",
  login: "Signing in...",
  startup: "Starting up...",
  handleRedirect: "Redirecting...",
  logout: "Signing out...",
  ssoSilent: "Singing in silently...",
};

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = ({
  buttonText,
  variant,
}: {
  buttonText?: string;
  variant?:
    | "contained"
    | "contained_icon"
    | "outlined"
    | "ghost"
    | "ghost_icon";
}) => {
  const { instance } = useMsal();

  return (
    <>
      <Button onClick={() => handleLogin(instance)} variant={variant}>
        {buttonText ? buttonText : `Sign in to ${process.env.REACT_APP_TITLE}`}
      </Button>
    </>
  );
};
