import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type PMGDimensionStandards = {
  DimStandardID: number;
  Description: string;
  ElementGroupID: number;
};

export type PMGDimensionStandardsCreate = {
  Description: string;
  ElementGroupID: string;
};

export type PMGDimensionStandardsUpdate = CommonUpdateProps & {
  Description: string;
  ElementGroupID: string;
};

export const pmgDimensionStandardsConfig: ItemAdminConfig<"pmg-dimension-standards"> =
  {
    title: "PMG Dimension Standards",
    singularTitle: "PMG Dimension Standard",
    url: "codelists/pmg-dimension-standards",
    itemIdProp: "DimStandardID",
    apiListProp: "getPMGDimStandard",
    nameProperty: ["DimStandardID", "Description"],
    columns: [
      {
        key: "DimStandardID",
        title: "ID",
      },
      { key: "Description", title: "Pipe Element Group", width: "60%" },
      {
        key: "ElementGroupID",
        title: "Element Group",
        codelist: "pipe-element-groups",
        width: "40%",
      },
    ],
    codelistsToQuery: ["pipe-element-groups"],
  };
