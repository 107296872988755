import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { EditComponentProps } from "../ItemEditModal";
import { useCodelist } from "queries/useCodelist";
import { useEffect, useMemo } from "react";

export function PMGDimensionStandardsEdit({
  item,
  setEditLoading,
}: EditComponentProps<"pmg-dimension-standards">) {
  const { data: pipeElementGroups, status: pipeElementGroupsStatus } =
    useCodelist({
      codelist: "pipe-element-groups",
    });

  const pipeElementGroupsOptions = useMemo(
    () =>
      pipeElementGroups
        ? pipeElementGroups.map((e) => ({
            id: String(e.ElementGroupID),
            option: e.ElementGroup,
          }))
        : [],
    [pipeElementGroups]
  );

  useEffect(() => {
    setEditLoading && setEditLoading(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    pipeElementGroupsStatus === "success" &&
      setEditLoading &&
      setEditLoading(false);
  }, [pipeElementGroupsStatus, setEditLoading]);

  return (
    <>
      <FieldsetContainer>
        <Fieldset>
          <Field
            area="pmg-dimension-standards"
            prop="Description"
            value={item ? item.Description : ""}
            title="Description"
            type="text"
          />
          <Field
            area="pmg-dimension-standards"
            prop="ElementGroupID"
            value={item ? item.ElementGroupID : "0"}
            title="Pipe Element Group"
            type="option"
            optionsWithIds={pipeElementGroupsOptions}
            status={pipeElementGroupsStatus}
            notSetValue={"0"}
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
