import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { EditComponentProps } from "../ItemEditModal";

export function ValveElementGroupsEdit({
  item,
}: EditComponentProps<"valve-element-groups">) {
  return (
    <>
      <FieldsetContainer>
        <Fieldset>
          {!item && (
            <Field
              area="valve-element-groups"
              prop="ValveGroupID"
              value=""
              title="Element Group ID"
              type="text"
              style={{ width: 120 }}
              disabled={!!item}
            />
          )}
          <Field
            area="valve-element-groups"
            prop="ValveGroup"
            value={item ? item.ValveGroup : ""}
            title="Element Group"
            type="text"
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
