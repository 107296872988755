import { PlantProps } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./../../app/store";

type PlantKind = "main" | "source";

type PlantState = {
  [kind in PlantKind]: PlantProps;
};

export const unsetPlantState: PlantProps = {
  OperatorID: 0,
  OperatorName: "",
  PlantID: 0,
  ShortDescription: "",
  Project: "",
  LongDescription: "",
  CommonLibPlantCode: "",
  InitialRevision: "0" as const,
  AreaID: 0,
  Area: "",
};

const initialState: PlantState = {
  main: unsetPlantState,
  source: unsetPlantState,
};

export const plantSlice = createSlice({
  name: "plant",
  initialState,
  reducers: {
    changeMainPlant: (state, action) => {
      state.main = action.payload;
    },
    changeSourcePlant: (state, action) => {
      state.source = action.payload;
    },
  },
});

export const { changeMainPlant, changeSourcePlant } = plantSlice.actions;

export const selectMainPlant = (state: RootState) => state.plant.main;
export const selectSourcePlant = (state: RootState) => state.plant.source;

export default plantSlice.reducer;
