import { codelistQueryFn, Codelists } from "queries/useCodelist";
import { useQueries } from "react-query";

export function useCodelists({ codelists }: { codelists?: Codelists[] }) {
  return useQueries(
    // @ts-ignore
    codelists
      ? codelists.map((codelist) => {
          return {
            queryKey: ["codelists", codelist],
            queryFn: codelistQueryFn,
          };
        })
      : []
  );
}
