import { ContentHeader } from "components/Components";
import { History } from "history";
import { ItemAdmin } from "./ItemAdmin";
import {
  isItemAdminArea,
  ItemAdminAreas,
  ItemAdminSections,
} from "./itemAdminConfig";
import { ListAdmin } from "./ListAdmin";
import { isListAdminArea, ListAdminAreas } from "./listAdminConfig";
import { PlantMappingAdmin } from "./plant-mapping/PlantMappingAdmin";
import { VDSSubsegmentGroupsConfig } from "./vds-subsegment-groups-config/VDSSubsegmentGroupsConfig";

export type ValidAdminAreas =
  | ItemAdminAreas
  | ListAdminAreas
  | "plant-mapping"
  | "vds-subsegment-groups-config"
  | "codetables";

export function Admin<T extends ValidAdminAreas>({
  area,
  section,
}: {
  area?: T;
  section?: T extends keyof ItemAdminAreas
    ? ItemAdminSections<T>[number]
    : string;
}) {
  return !area ? (
    <ContentHeader>No area.</ContentHeader>
  ) : area && area === "codetables" ? (
    <ContentHeader>Choose a Code Table.</ContentHeader>
  ) : isItemAdminArea(area) ? (
    <ItemAdmin area={area} section={section} />
  ) : isListAdminArea(area) ? (
    <ListAdmin area={area} />
  ) : area === "plant-mapping" ? (
    <PlantMappingAdmin section={section as string} />
  ) : area === "vds-subsegment-groups-config" ? (
    <VDSSubsegmentGroupsConfig />
  ) : (
    <ContentHeader>No such area: {area}.</ContentHeader>
  );
}

export const closeAdminModal = ({ history }: { history: History }) => {
  history.push({ search: "" });
};

export type AdminProps = React.ComponentType<typeof Admin>;
