import { ContentHeader, ContentMessage } from "../../components/Components";
import { NavLink } from "react-router-dom";
import { Typography } from "@equinor/eds-core-react";
import { sheetTypeNames } from "../sheets/config/sheetConfig";

export default function SheetsTestpageList() {
  return (
    <>
      <ContentHeader>Sheets Testpages</ContentHeader>
      <ContentMessage>
        <ul>
          {Object.keys(sheetTypeNames).map((key) => {
            return (
              <li key={key}>
                <NavLink exact to={`/testpage/sheets/${key}`}>
                  <Typography>{sheetTypeNames[key]}</Typography>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </ContentMessage>
    </>
  );
}
