import { Search } from "@equinor/eds-core-react";
import { ControlHeaderContainer } from "components/table/Table";
import { useState } from "react";
import { filterItemsByName } from "utils/filterItemsByName";
import { exampleItems, TableExample } from "./TableExample";

export function TableWithFilterExample() {
  const [nameFilter, setNameFilter] = useState("");

  return (
    <TableExample
      controlHeader={
        <ControlHeaderContainer>
          <Search
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            placeholder="Filter all columns"
          />
        </ControlHeaderContainer>
      }
      items={filterItemsByName({
        nameFilter,
        items: exampleItems,
        nameProperty: ["id", "txt"],
      })}
      infoline={true}
      totalCount={exampleItems.length}
    />
  );
}
