import { Button, Input, Typography } from "@equinor/eds-core-react";
import { PageContainer } from "components/Components";
import { FormRowBlock } from "components/form/Form";
import { useState } from "react";
import { useForm } from "react-hook-form";

export function FormExample() {
  const [submittedData, setSubmittedData] = useState({});

  const { register, handleSubmit } = useForm();

  const onSubmit = handleSubmit((data) => {
    setSubmittedData(data);
  });

  return (
    <PageContainer>
      <form onSubmit={onSubmit}>
        <FormRowBlock>
          <Input {...register("text")} />
        </FormRowBlock>
        <Button type="submit">Submit</Button>
      </form>
      <div style={{ marginTop: 20 }}>
        <Typography variant="h5">Submitted data</Typography>
        {JSON.stringify(submittedData)}
      </div>
    </PageContainer>
  );
}
