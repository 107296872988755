import { PlantProps } from "../plant/types";
import Sheets from "./Sheets";
import { useParams } from "react-router-dom";
import {
  SheetTypes,
  sheetProperties,
  ListSheetsDisplayTypes,
} from "./config/sheetConfig";
import { ContentHeader } from "../../components/Components";
import { Typography } from "@equinor/eds-core-react";
import { SheetCommands } from "./types";
import CopySheetsModal from "./modals/CopySheets";

export default function MainSheets({
  displayType,
  plant,
}: {
  plant: PlantProps;
  displayType: ListSheetsDisplayTypes;
}) {
  const { sheetType, sheetCommand } = useParams<{
    sheetType: SheetTypes;
    sheetCommand: SheetCommands;
  }>();
  if (!sheetType || !sheetProperties.hasOwnProperty(sheetType)) {
    return (
      <ContentHeader>
        <Typography color="danger">Unknown data sheet type.</Typography>
      </ContentHeader>
    );
  }
  return (
    <>
      <Sheets
        plant={plant}
        displayType={displayType}
        sheetType={sheetType}
        sheetCommand={sheetCommand}
      />
      <CopySheetsModal />
    </>
  );
}
