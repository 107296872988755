import { loginRequest, msalConfigSilent } from "./authConfig";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";

export const acquireAccessToken = async () => {
  const msalInstance = new PublicClientApplication(msalConfigSilent);
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw Error("Sign in needed.");
  }
  const request = {
    ...loginRequest,
    account: activeAccount || accounts[0],
  };

  const authResult = await msalInstance
    .acquireTokenSilent(request)
    .catch(function (error) {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(request).catch(function (error) {
          console.error(error);
          throw new Error("Login error.");
        });
      }
    });
  return authResult ? authResult.accessToken : "";
};
