import { Switch } from "@equinor/eds-core-react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { Field } from "components/form/Field";
import { EditComponentProps } from "../ItemEditModal";
import { useMemo } from "use-memo-one";

export function PCSStandardNotesEdit({
  item,
  data,
  edit,
  section,
}: EditComponentProps<"pcs-standard-notes">) {
  const { register } = useFormContext();
  const [createNewCategory, setCreateNewCategory] = useState(false);
  const categoryOptions = useMemo(
    () =>
      data &&
      data.reduce(
        (a, c) => (a.includes(c.Category) ? a : [...a, c.Category]),
        [] as string[]
      ),
    [data]
  );

  return (
    <>
      <FieldsetContainer>
        <Fieldset>
          {edit === null && (
            <input
              type="hidden"
              defaultValue={section}
              {...register("NoteSection")}
            />
          )}
          <Field
            area="pcs-standard-notes"
            prop="Category"
            title="Category"
            type={createNewCategory ? "text" : "option"}
            maxWidth={270}
            value={item ? item.Category : ""}
            options={categoryOptions}
            required={true}
            afterInputContent={
              <Switch
                onClick={() => setCreateNewCategory(!createNewCategory)}
                label="Create new Category"
              />
            }
          />
          <Field
            area="pcs-standard-notes"
            prop="NoteText"
            title="Note"
            type="text"
            value={item ? item.NoteText : ""}
            rows={4}
            rowsMax={8}
            required={true}
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
