import { Access } from "Access";
import { Issues } from "./Issues";

export function AccessIssues() {
  return (
    <Access cap="sheets" role="A">
      <Issues />
    </Access>
  );
}
