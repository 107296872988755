import { ReportsConfig } from "../Reports";
import { vdsTextblockSearchColumns } from "./VDSTextblockSearchReport";

export type VDSTextblockSearchData = {
  VDS: string;
  Revision: string;
  Status: string;
}[];

export const vdsTextblockSearchConfig: ReportsConfig = {
  title: "VDS Textblock used in VDS",
  menuTitle: "VDS Textblock used in VDS",
  listProperty: "getTextBlockSearchVDS",
  exportFunction: (data) => ({
    columns: vdsTextblockSearchColumns,
    items: data,
  }),
};
