import { Button, Label, Radio } from "@equinor/eds-core-react";
import { PageContainer } from "components/Components";
import { FormOptionsContainer, FormRowBlock } from "components/form/Form";
import {
  ModalLayers,
  ModalSideMargin,
  ModalWindow,
} from "components/ModalWindow";
import { useState } from "react";

export function ModalLayersExample() {
  const [showModal, setShowModal] = useState(false);
  const [modalLayer, setModalLayer] = useState<ModalLayers>("default");
  return (
    <PageContainer>
      <FormRowBlock>
        <Label label="Modal Layer" />
        <FormOptionsContainer>
          <Radio
            label="default"
            checked={modalLayer === "default"}
            onChange={() => setModalLayer("default")}
          />
          <Radio
            label="secondary"
            checked={modalLayer === "secondary"}
            onChange={() => setModalLayer("secondary")}
          />
          <Radio
            label="primary"
            checked={modalLayer === "primary"}
            onChange={() => setModalLayer("primary")}
          />
        </FormOptionsContainer>
      </FormRowBlock>
      <Button onClick={() => setShowModal(true)}>Show modal</Button>
      <ModalWindow
        closeModal={() => setShowModal(false)}
        isOpen={showModal}
        title={"Modal Example"}
        layer={modalLayer}
      >
        <ModalSideMargin>I'm here</ModalSideMargin>
      </ModalWindow>
    </PageContainer>
  );
}
