import { Category, MenuItem } from "components/MainMenuComponents";
import { lightbulb } from "@equinor/eds-icons";

export default function TestpageMenu() {
  return (
    <Category title="Test pages">
      <MenuItem
        title="Sheets"
        to="/testpage/sheets/"
        icon={lightbulb}
        notExact={true}
      ></MenuItem>
      <MenuItem
        title="Pipe Elements"
        to="/testpage/pipe-elements/"
        icon={lightbulb}
      ></MenuItem>
      <MenuItem title="Table" to="/testpage/table/" icon={lightbulb}></MenuItem>
      <MenuItem
        title="TableGroup"
        to="/testpage/tablegroup/"
        icon={lightbulb}
      ></MenuItem>
      <MenuItem
        title="ModalWindow"
        to="/testpage/modalwindow/"
        icon={lightbulb}
      ></MenuItem>
    </Category>
  );
}
