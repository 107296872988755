import { Button, Icon } from "@equinor/eds-core-react";
import { flame } from "@equinor/eds-icons";
import { ExampleItemType, TableExample } from "./TableExample";

function TableRowMenuExample({ item }: { item: ExampleItemType }) {
  return (
    <Button variant="ghost_icon" onClick={() => alert(item.id)}>
      <Icon data={flame} />
    </Button>
  );
}

export function TableWithButtonExample() {
  return <TableExample Commands={TableRowMenuExample} />;
}
