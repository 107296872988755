import {
  Button,
  Icon,
  Popover,
  Switch,
  Tooltip,
} from "@equinor/eds-core-react";
import { settings } from "@equinor/eds-icons";
import React from "react";
import ReactDOM from "react-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectUIState, setDarkMode } from "./uiSlice";
import { portalContainer } from "./utils/util";

export default function SettingsMenu() {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const closePopover = () => setIsOpen(false);
  const togglePopover = () => setIsOpen(!isOpen);
  const dispatch = useAppDispatch();
  const { darkMode, showAlpha } = useAppSelector(selectUIState);

  const popover = ReactDOM.createPortal(
    <Popover
      anchorEl={anchorRef.current}
      open={isOpen}
      onClose={closePopover}
      placement="bottom-end"
    >
      <Popover.Header>
        <Popover.Title>Settings</Popover.Title>
      </Popover.Header>
      <Popover.Content>
        {showAlpha && (
          <div>
            <Switch
              checked={darkMode}
              onChange={() => dispatch(setDarkMode(!darkMode))}
              label="Dark mode (Alpha)"
            />
          </div>
        )}
      </Popover.Content>
    </Popover>,
    portalContainer
  );

  return (
    <>
      <Tooltip title="Settings">
        <Button ref={anchorRef} variant="ghost_icon" onClick={togglePopover}>
          <Icon data={settings} />
        </Button>
      </Tooltip>
      {popover}
    </>
  );
}
