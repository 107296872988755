import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type VDSCharacteristics = {
  CharacteristicID: number;
  Description: string;
};

export type VDSCharacteristicsCreate = {
  Description: string;
};

export type VDSCharacteristicsUpdate = CommonUpdateProps & {
  Description: string;
};

export const vdsCharacteristicsConfig: ItemAdminConfig<"vds-characteristics"> =
  {
    title: "VDS Characteristics",
    singularTitle: "VDS Characteristics",
    url: "codelists/vds-characteristics",
    itemIdProp: "CharacteristicID",
    apiListProp: "getVDSCharacteristics",
    nameProperty: ["CharacteristicID", "Description"],
    columns: [
      {
        key: "CharacteristicID",
        title: "ID",
      },
      { key: "Description", title: "Description", width: "100%" },
    ],
  };
