import { NativeSelect } from "@equinor/eds-core-react";
import { Field } from "components/form/Field";
import { FieldsetContainer, Fieldset, FormRow } from "components/form/Form";
import { useMemo } from "react";
import { ListAdminProps } from "../listAdminConfig";

export function MaterialGroupsEdit({
  item,
  data,
  position,
  setPosition,
}: {
  item?: ListAdminProps<"material-groups">;
  data?: ListAdminProps<"material-groups">[];
  position: number;
  setPosition: React.Dispatch<number>;
}) {
  const insertPositions = useMemo(
    () => [
      { id: 0, option: "First position" },
      { id: data ? data.length : 1, option: "Last position" },
      ...(data
        ? data.map((e: ListAdminProps<"material-groups">, i: number) => ({
            id: i + 1,
            option: `Below ${e.MaterialGroup} [${e.MaterialGroupID}]`,
          }))
        : []),
    ],
    [data]
  );

  return (
    <>
      <FieldsetContainer slim>
        <Fieldset>
          <FormRow>
            <NativeSelect
              label="Position"
              value={position}
              id="insert-at"
              onChange={(e) => setPosition(Number(e.target.value))}
            >
              {insertPositions.map((e) => (
                <option value={e.id} key={e.id + e.option}>
                  {e.option}
                </option>
              ))}
            </NativeSelect>
          </FormRow>
        </Fieldset>
        <Fieldset>
          <Field
            area="material-groups"
            prop="MaterialGroup"
            value={item ? item.MaterialGroup : ""}
            title="Material Group"
            type="text"
          />
          <Field
            area="material-groups"
            prop="Description"
            value={item ? item.Description : ""}
            title="Description"
            type="text"
          />
          <Field
            area="material-groups"
            prop="Density"
            value={item ? item.Density : "0"}
            title="Density (kg/m³)"
            type="text"
            maxWidth={200}
          />
          <Field
            area="material-groups"
            prop="VDSCode1"
            value={item ? item.VDSCode1 : ""}
            title="MDS/VDS #1"
            type="text"
            maxWidth={200}
          />
          <Field
            area="material-groups"
            prop="VDSCode2"
            value={item && item.VDSCode2 ? item.VDSCode2 : ""}
            title="MDS/VDS #2"
            type="text"
            maxWidth={200}
          />
          <Field
            area="material-groups"
            prop="MaterialType"
            value={item ? item.MaterialType : ""}
            title="Material Type"
            type="text"
          />
          <Field
            area="material-groups"
            prop="PEBEWthMM"
            value={item ? item.PEBEWthMM : ""}
            title="PE/BE (mm)"
            type="text"
            maxWidth={200}
          />
          <Field
            area="material-groups"
            prop="MinDesignTemperature"
            value={item ? item.MinDesignTemperature : ""}
            title="Minimum Design Temperature"
            type="text"
            maxWidth={200}
          />
          <Field
            area="material-groups"
            prop="AllowableStress"
            value={item ? item.AllowableStress : ""}
            title="Allowable Stress (Olet)"
            type="text"
            maxWidth={200}
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
