import { ReportsConfig } from "../Reports";
import { exportMaterialRatingGroups } from "./MaterialRatingGroupsReport";

export type MaterialRatingGroupsData = {
  MaterialGroup: string;
  RatingGroup: string;
}[];

export const materialRatingGroupsConfig: ReportsConfig = {
  title: "Material and Rating Groups",
  menuTitle: "Material and Rating Groups",
  listProperty: "getMatRatGroup",
  exportFunction: exportMaterialRatingGroups,
};
