import { useHistory, useLocation } from "react-router-dom";

export function saveActiveTab(name: string, tabName: string) {
  window.sessionStorage.setItem(name, tabName);
}

export function useNamedTabs<T extends string>({
  name,
  changeConfirm,
  tabs,
}: {
  name: string;
  changeConfirm?: () => Promise<boolean>;
  tabs: T[];
}): {
  activeTabName: T;
  activeTab: number;
  handleChange: (index: number) => void;
} {
  const savedActiveTab = window.sessionStorage.getItem(name);
  const { search } = useLocation();
  const history = useHistory();
  const parsedSearch = new URLSearchParams(search);

  const setActiveTab = (tabName: string) => {
    parsedSearch.set("tab", tabName);
    history.replace({ search: parsedSearch.toString() });
    saveActiveTab(name, tabName);
  };

  const handleChange = async (index: number) => {
    changeConfirm
      ? (await changeConfirm()) && setActiveTab(tabs[index])
      : setActiveTab(tabs[index]);
  };

  const currentTab = (
    parsedSearch.get("tab")
      ? (parsedSearch.get("tab") as string)
      : savedActiveTab
      ? savedActiveTab
      : tabs[0]
  ) as T;

  return {
    activeTab: tabs.indexOf(currentTab) > -1 ? tabs.indexOf(currentTab) : 0,
    activeTabName: tabs.includes(currentTab) ? currentTab : tabs[0],
    handleChange,
  };
}
