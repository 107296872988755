import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type ValveElementGroups = {
  ValveGroupID: number;
  ValveGroup: string;
};

export type ValveElementGroupsCreate = {
  ValveGroupID: string;
  ValveGroup: string;
};

export type ValveElementGroupsUpdate = CommonUpdateProps & {
  ValveGroup: string;
};

export const valveElementGroupsConfig: ItemAdminConfig<"valve-element-groups"> =
  {
    title: "Valve Element Groups",
    singularTitle: "Valve Element Group",
    url: "codelists/valve-element-groups",
    itemIdProp: "ValveGroupID",
    apiListProp: "getValveElementGroups",
    nameProperty: ["ValveGroupID", "ValveGroup"],
    columns: [
      {
        key: "ValveGroupID",
        title: "ID",
      },
      { key: "ValveGroup", title: "Valve Element Group", width: "100%" },
    ],
  };
