import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { EditComponentProps } from "../ItemEditModal";
import { useCodelist } from "queries/useCodelist";
import { useEffect, useMemo } from "react";
import { FlexColumnContainer, FlexColumnElement } from "components/Components";

export function ValveTypesEdit({
  item,
  setEditLoading,
}: EditComponentProps<"valve-types">) {
  const { data: valveElementGroups, status: valveElementGroupsStatus } =
    useCodelist({
      codelist: "valve-element-groups",
    });

  const valveElementGroupsOptions = useMemo(
    () =>
      valveElementGroups
        ? valveElementGroups.map((e) => ({
            id: String(e.ValveGroupID),
            option: e.ValveGroup,
          }))
        : [],
    [valveElementGroups]
  );

  useEffect(() => {
    setEditLoading && setEditLoading(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    valveElementGroupsStatus === "success" &&
      setEditLoading &&
      setEditLoading(false);
  }, [valveElementGroupsStatus, setEditLoading]);

  return (
    <FlexColumnContainer>
      <FlexColumnElement>
        <FieldsetContainer>
          <Fieldset>
            {!item && (
              <Field
                area="valve-types"
                prop="ValveTypeID"
                value=""
                title="Valve Type ID"
                type="text"
                style={{ width: 120 }}
                disabled={!!item}
              />
            )}
            <Field
              area="valve-types"
              prop="Description"
              value={item ? item.Description : ""}
              title="Short Description"
              type="text"
            />
            <Field
              area="valve-types"
              prop="LongDescription"
              value={item ? item.LongDescription : ""}
              title="Long Description"
              type="text"
            />
            <Field
              area="valve-types"
              prop="VDSCode"
              value={item ? item.VDSCode : ""}
              title="Code"
              type="text"
              style={{ width: 120 }}
            />
            <Field
              area="valve-types"
              prop="ValveCategoryID"
              value={item ? item.ValveCategoryID : "0"}
              title="Valve Element Group"
              type="option"
              optionsWithIds={valveElementGroupsOptions}
              status={valveElementGroupsStatus}
              notSetValue={"0"}
            />
            <Field
              area="valve-types"
              prop="MainValveType"
              value={item ? item.MainValveType : ""}
              title="Main Valve Type"
              type="text"
            />
            <Field
              area="valve-types"
              prop="SubValveType"
              value={item ? item.SubValveType : ""}
              title="Sub Valve Type"
              type="text"
            />
          </Fieldset>
        </FieldsetContainer>
      </FlexColumnElement>
      <FlexColumnElement>
        <FieldsetContainer>
          <Fieldset>
            <Field
              area="valve-types"
              prop="Seat"
              value={item ? item.Seat : ""}
              title="Seat"
              type="text"
            />
            <Field
              area="valve-types"
              prop="Pattern"
              value={item ? item.Pattern : ""}
              title="Pattern"
              type="text"
            />
            <Field
              area="valve-types"
              prop="Miscellaneous"
              value={item ? item.Miscellaneous : ""}
              title="Miscellaneous"
              type="text"
            />
          </Fieldset>
          <Fieldset slim>
            <Field
              area="valve-types"
              prop="HDIndicator"
              value={item ? item.HDIndicator : "N"}
              title="Housing Design"
              type="checkbox"
            />
            <Field
              area="valve-types"
              prop="FBRBIndicator"
              value={item ? item.FBRBIndicator : "N"}
              title="Full / Reduced Bore"
              type="checkbox"
            />
          </Fieldset>
        </FieldsetContainer>
      </FlexColumnElement>
    </FlexColumnContainer>
  );
}
