import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type VDSDesign = {
  ItemID: number;
  Description: string;
};

export type VDSDesignCreate = {
  ItemID: string;
  Description: string;
};

export type VDSDesignUpdate = CommonUpdateProps & {
  Description: string;
};

export const vdsDesignConfig: ItemAdminConfig<"vds-design"> = {
  title: "VDS Designs",
  singularTitle: "VDS Design",
  url: "codelists/vds-design",
  itemIdProp: "ItemID",
  apiListProp: "getVDSDesign",
  nameProperty: ["ItemID", "Description"],
  columns: [
    {
      key: "ItemID",
      title: "ID",
    },
    { key: "Description", title: "Description", width: "100%" },
  ],
};
