import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type ValveTypes = {
  ValveTypeID: number;
  Description: string;
  VDSCode: string;
  LongDescription: string;
  FBRBIndicator: "N" | "Y";
  ValveCategoryID: number;
  MainValveType: string;
  SubValveType: string;
  Seat: string;
  Pattern: string;
  Miscellaneous: string;
  HDIndicator: "N" | "Y";
};

export type ValveTypesCreate = ValveTypes;

export type ValveTypesUpdate = CommonUpdateProps &
  Omit<ValveTypes, "ValveTypeID">;

export const valveTypesConfig: ItemAdminConfig<"valve-types"> = {
  title: "Valve Types",
  singularTitle: "Valve Type",
  url: "codelists/valve-types",
  itemIdProp: "ValveTypeID",
  apiListProp: "getValveTypes",
  nameProperty: [
    "ValveTypeID",
    "Description",
    "LongDescription",
    "VDSCode",
    "MainValveType",
    "SubValveType",
    "Seat",
    "Pattern",
    "Miscellaneous",
  ],
  columns: [
    {
      key: "ValveTypeID",
      title: "ID",
    },
    {
      key: "Description",
      title: "Short Description",
      width: "15%",
      minWidth: 150,
    },
    {
      key: "LongDescription",
      title: "Long Description",
      width: "15%",
      minWidth: 200,
    },
    { key: "VDSCode", title: "Code", width: "10%" },
    {
      key: "ValveCategoryID",
      title: "Valve Element Group",
      codelist: "valve-element-groups",
      width: "40%",
    },
    { key: "MainValveType", title: "Main Valve Type", width: "10%" },
    { key: "SubValveType", title: "Sub Valve Type", width: "10%" },
    { key: "Seat", title: "Seat", width: "10%", minWidth: 150 },
    { key: "Pattern", title: "Pattern", width: "15%", minWidth: 150 },
    { key: "Miscellaneous", title: "Miscellaneous", width: "10%" },
    { key: "HDIndicator", title: "Housing Design", width: "5%" },
    { key: "FBRBIndicator", title: "Full / Reduced Bore", width: "5%" },
  ],
  codelistsToQuery: ["valve-element-groups"],
};
