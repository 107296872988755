import { ReportsConfig } from "../Reports";
import { exportAllowableStressMatrix } from "./AllowableStressMatrixReport";

export type AllowableStressMatrixData = {
  MaterialGroup: string;
  MaterialStandard: string;
  MaterialGrade: string;
  Temperature: number;
  AllowableStress: number;
}[];

export const allowableStressMatrixConfig: ReportsConfig = {
  title: "Allowable Stress Values (MPa)",
  menuTitle: "Allowable Stress Matrix",
  listProperty: "getAllowableStress",
  exportFunction: exportAllowableStressMatrix,
};
