import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type VDSMaterial = {
  ItemID: number;
  Description: string;
};

export type VDSMaterialCreate = {
  ItemID: string;
  Description: string;
};

export type VDSMaterialUpdate = CommonUpdateProps & {
  Description: string;
};

export const vdsMaterialConfig: ItemAdminConfig<"vds-material"> = {
  title: "VDS Materials",
  singularTitle: "VDS Material",
  url: "codelists/vds-material",
  itemIdProp: "ItemID",
  apiListProp: "getVDSMaterial",
  nameProperty: ["ItemID", "Description"],
  columns: [
    {
      key: "ItemID",
      title: "ID",
    },
    { key: "Description", title: "Description", width: "100%" },
  ],
};
